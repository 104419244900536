import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Answers } from "./Answers";
import ErrorPage from "./ErrorPage";
import "./fonts/Avenir-Medium-09.ttf";
import "./fonts/Avenir-Black-03.ttf";

const router = createBrowserRouter([
  {
    path: "/answers",
    element: <Answers url={"/api/answer/"} access={true} />,
  },
  {
    path: "/preview",
    element: <Answers url={"/api/preview/"} />,
  },
  {
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
