import * as React from "react";
import { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import "./App.css";
import { Button, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Download } from "./Download";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { styles } from "./Download";

const colors = {
  main: "#C00000",
};
const theme = createTheme({
  palette: {
    primary: {
      main: colors.main,
      contrastTypography: "#242105",
    },
  },
  typography: {
    fontFamily: "Arial",
    // h1: {
    //   fontFamily: "AvenirBlack",
    //   fontSize: 23.5,
    //   fontWeight: 600,
    //   color: colors.main,
    // },
    // body2: {
    //   fontFamily: "Arial",
    //   fontSize: 13.5,
    //   fontWeight: 500,
    // },
  },
});
function getRandomElement(list) {
  const index = Math.floor(Math.random() * list.length);
  return list[index];
}
const sampleQuestions = [
  "How can Salesforce improve sales reps’ productivity?",
  "How can UKG reduce an organization's payroll error rate?",
  "What are the financial benefits Zoho Creator delivers?",
  "How much can an organization benefit by moving from on-premises to the cloud?",
  "How can an organization quantify the returns of a predictive maintenance deployment?",
  "What is the quantified value of a dedicated SCP solution?",
];
const getRandomWord = (i) => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  return [...Array(i).keys()]
    .map((i) => alphabet[Math.floor(Math.random() * alphabet.length)])
    .join("");
};
const getFakeText = (length) => {
  let sum = 0;
  let output = [""];
  while (sum < length) {
    const num = Math.floor(Math.random() * 12);
    output.push(getRandomWord(num));
    sum += num;
  }
  return String(output.join(" "));
};

export const Answers = ({ url, access = false }) => {
  const [answer, setAnswer] = useState(null);
  const [answerList, setAnswerList] = useState(null);
  const [length, setLength] = useState(null);
  const [queryText, setQueryText] = useState("");
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [research, setResearch] = useState();
  const [progress, setProgress] = React.useState(0);

  const label = useMemo(() => getRandomElement(sampleQuestions), []);
  const isBusinessCase = useMemo(
    () => question?.toLowerCase()?.startsWith("build a business case for"),
    [question]
  );

  const getData = useCallback(
    async (question, attempts = 0) => {
      setLoading(true);
      await axios
        .get(`${url}${question}`)
        .then((response) => {
          if (response?.data?.answerList) {
            setAnswerList(response?.data?.answerList);
            setAnswer(null);
            setResearch(null);
            setLength(null);
          } else {
            setAnswer(response?.data?.answer);
            setAnswerList(null);
            setResearch(response?.data?.research);
            setLength(response?.data?.length);
          }
          setLoading(false);
        })
        .catch((error) => {
          setAnswer(null);
          setAnswerList(null);
          const retry = () => {
            attempts < 3 ? getData(question, attempts + 1) : setLoading(false);
          };
          setTimeout(retry, 500);
        });
    },
    [url]
  );

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          return Math.min(oldProgress + (isBusinessCase ? 0.5 : 5), 98);
        });
      }, 3000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isBusinessCase, loading]);

  return (
    <div className="App">
      {window !== window.top && (
        <ThemeProvider theme={theme}>
          <div
            style={{
              paddingTop: 40,
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              width: "100%",
              fontFamily: "Arial",
            }}
          >
            <div
              style={{
                width: "95%",
                maxWidth: 800,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  paddingTop: 0,
                  paddingBottom: 30,
                  display: "flex",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder={label}
                  style={{ marginRight: 20, width: "100%" }}
                  onChange={(res) => setQuestion(res.currentTarget.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !loading) {
                      getData(question);
                      setQueryText(question);
                      setProgress(0);
                    }
                  }}
                />
                <Button
                  disabled={loading || !question}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    getData(question);
                    setQueryText(question);
                    setProgress(0);
                  }}
                >
                  Ask
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: 20,
                }}
              >
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* <CircularProgress /> */}

                    <Box sx={{ width: "50%", marginTop: "50px" }}>
                      <LinearProgress variant="determinate" value={progress} />
                    </Box>
                  </div>
                ) : answer || answerList ? (
                  <div
                    style={{
                      maxWidth: 900,
                      whiteSpace: "pre-line",
                    }}
                  >
                    {answerList ? (
                      <span>
                        <div style={{ fontWeight: 600, color: "#C00000" }}>
                          Executive Summary
                        </div>
                        <div>{answerList[3]}</div>
                        <div style={{ fontWeight: 600, color: "#C00000" }}>
                          Market Status
                        </div>
                        <div>{answerList[0]}</div>
                        <div style={{ fontWeight: 600, color: "#C00000" }}>
                          Product
                        </div>
                        <div>{answerList[1]}</div>
                        <div style={{ fontWeight: 600, color: "#C00000" }}>
                          Projected Benefits
                        </div>
                        <div>{answerList[2]}</div>
                      </span>
                    ) : (
                      answer
                    )}
                    {!access && (
                      <span
                        style={{
                          filter: "blur(3px)",
                          // marginLeft: 5,
                          marginTop: -2,
                          maxWidth: 900,
                          whiteSpace: "pre-line",
                        }}
                        className="noselect"
                      >
                        {getFakeText(length)}
                      </span>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {!loading && ((answer && research) || answerList) && access && (
                <Download
                  question={queryText}
                  answer={answer}
                  answerList={answerList}
                  research={research}
                />
              )}
            </div>
          </div>
        </ThemeProvider>
      )}
    </div>
  );
};
